import React from "react"
import { graphql } from 'gatsby'

import Layout from '../../../layout'

import BackgroundContainer from '../../../components/image/background-container'
import TitleContainer from '../../../components/common/title-container'
import Title from '../../../components/common/title'
import Subtitle from '../../../components/common/subtitle'
import PageContainer from '../../../components/common/page-container'

export default (props) => (
  <Layout title="Voice">
    <BackgroundContainer header={props.data.header}>
      <TitleContainer>
        <Title>Voice</Title>
        <Subtitle>A new way to engage your customers</Subtitle>
      </TitleContainer>
    </BackgroundContainer>

    <PageContainer>
      <p>Alexa and Google Home are becoming increasingly popular and appear in almost a quarter UK of homes, with 41 per cent of homes planning to own one of these devices in the next five years. Whether you're looking to target a younger audience or want to deliver information to a busy user, voice is the great way to do this.</p>

      <p>Voice applications can be used to complement an existing application and can be used to relay key information (e.g. website uptime) in an manner which is easy to digest.</p>

      <p>packagedby can work with you and your business to build the perfect voice app for your customers. We have extensive experience with building Alexa Skills and Google Actions.</p>
    </PageContainer>
  </Layout>
)

export const query = graphql`
  query {
    header: file(relativePath: { eq: "voice.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 1000, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`